import { Sub } from "~/types/subscriptions";

import { log } from "~/composables/log";

export default defineNuxtRouteMiddleware(() => {
    if (__subscription().getIsReadonly(Sub.Type.ObjectStorage)) {
        log("❌ Read-only S3");
        return navigateTo("/");
    }

    log("✅ Editable S3");
});
